import phoneVerificationService from "@/services/phoneVerificationService";
import { computed, ref } from "vue";

export default function usePhoneVerification(initialPhoneVerified) {
  const isValidPhoneInput = ref(false);
  const phoneVerified = ref(initialPhoneVerified);
  const otpVerified = ref("");
  const isSendingSms = ref(false);
  const isSmsSent = ref(false);
  const timeBeforeNextSms = ref(0);
  const TIME_BEFORE_NEXT_SMS_IN_MS = 60000;
  const verifAttempts = ref(phoneVerificationService.getVerifAttempts());

  const canSendSms = computed(() => {
    return (
      isValidPhoneInput.value && // Phone is complete
      timeBeforeNextSms.value <= 0 && // Enough time from the last attempt
      !isSendingSms.value && //  NOT sending SMS
      verifAttempts.value > 0 // Validation attempt left
    );
  });

  const sendSmsOtp = async (phone) => {
    console.log(`SEND_SMS_TO: ${phone}`);
    if (!canSendSms.value) return;
    isSendingSms.value = true;
    _useVerifAttempt();
    _startBeforeNextSmsTimer();
    // TODO: catch error
    await phoneVerificationService.sendSmsOtp(phone).catch((error) => {
      console.error(error);
    });
    _syncVerifAttempts();
    isSmsSent.value = true;
    isSendingSms.value = false;
  };

  const verifyPhone = async (phone, otp) => {
    console.log(`VERIFY_PHONE: phone: '${phone}'; otp: '${otp}'`);
    console.log({ otp });

    // OTP for tests: 999999
    const isVerified =
      otp === "999999" || (await phoneVerificationService.verify(phone, otp));
    if (isVerified) {
      phoneVerified.value = phone;
      otpVerified.value = otp;
    } else {
      phoneVerified.value = "";
      otpVerified.value = "";
    }
    return isVerified;
  };

  const _startBeforeNextSmsTimer = () => {
    // Countdown before next OTP SMS attempt
    timeBeforeNextSms.value = TIME_BEFORE_NEXT_SMS_IN_MS;
    const timer = setInterval(() => {
      timeBeforeNextSms.value -= 1000;
      if (timeBeforeNextSms.value <= 0) {
        clearInterval(timer);
        timeBeforeNextSms.value = 0;
      }
    }, 1000);
  };

  const _useVerifAttempt = () => {
    verifAttempts.value = verifAttempts.value - 1;
  };

  // ! TODO: critical -> save verif attempts in DB
  const _syncVerifAttempts = () => {
    verifAttempts.value = phoneVerificationService.getVerifAttempts();
  };

  return {
    phoneVerified,
    otpVerified,
    isValidPhoneInput,
    isSmsSent,
    timeBeforeNextSms,
    canSendSms,
    verifAttempts,
    sendSmsOtp,
    verifyPhone,
  };
}
