<template>
  <form class="form">
    <div class="inputs">
      <InputTextNameFirstValidation
        v-model="nameFirst"
        @update:is-valid-name-first="validation.nameFirst = $event"
      />
      <InputTextNameLastRegistration
        v-model="nameLast"
        @update:is-valid-name-last="validation.nameLast = $event"
      />

      <div class="inputs__sex-age-container">
        <SelectBlockSexValidation
          v-model="sex"
          @update:is-valid-sex="validation.sex = $event"
        />
        <InputTextDOBRegistration
          v-model:dob-date="dob"
          @update:is-valid-dob="validation.dob = $event"
        />
      </div>

      <div class="inputs__zip-container">
        <InputTextZipValidation
          v-model="zip"
          @update:is-valid-zip="validation.zip = $event"
        />
      </div>

      <BlockTelInput
        v-model:phone="phone"
        @update:is-verified="validation.phone = $event"
      />
    </div>

    <CheckboxPolicyConsent v-model="validation.policyConsent" />

    <ButtonPrimaryDone
      @click="$emit('signup')"
      :isDisabled="isButtonDisabled"
    />
  </form>
</template>

<script>
import { computed, reactive } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";
import { storeToRefs } from "pinia";

import InputTextNameFirstValidation from "@/components/UI/InputTextNameFirstValidation.vue";
import InputTextZipValidation from "@/components/UI/InputTextZipValidation.vue";
import ButtonPrimaryDone from "@/components/UI/ButtonPrimaryDone.vue";
import InputTextNameLastRegistration from "@/components/UI/InputTextNameLastRegistration.vue";
import SelectBlockSexValidation from "@/components/UI/SelectBlockSexValidation.vue";
import InputTextDOBRegistration from "@/components/UI/InputTextDOBRegistration.vue";
import BlockTelInput from "@/components/BlockTelInput.vue";
import CheckboxPolicyConsent from "./CheckboxPolicyConsent.vue";

export default {
  components: {
    InputTextNameFirstValidation,
    InputTextZipValidation,
    ButtonPrimaryDone,
    InputTextNameLastRegistration,
    SelectBlockSexValidation,
    InputTextDOBRegistration,
    BlockTelInput,
    CheckboxPolicyConsent,
  },

  emits: ["signup"],

  setup() {
    const userProfileStore = useUserProfileStore();

    const validation = reactive({
      nameFirst: false,
      nameLast: false,
      sex: false,
      dob: false,
      zip: false,
      phone: false,
      policyConsent: false,
    });

    const isButtonDisabled = computed(() => {
      return !(
        validation.nameFirst &&
        validation.nameLast &&
        validation.sex &&
        validation.dob &&
        validation.zip &&
        validation.phone &&
        validation.policyConsent
      );
    });

    return {
      validation,
      ...storeToRefs(userProfileStore),
      isButtonDisabled,
    };
  },
};
</script>

<style scoped>
.form {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  row-gap: 60px;
}
.inputs {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}
.inputs__zip-container {
  width: 100%;
}
.inputs__sex-age-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.policy-consent-container {
  display: flex;
  column-gap: 10px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .form {
    row-gap: 60px;
  }
}
</style>
